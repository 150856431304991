<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <ol class="carousel-indicators">
      <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></li>
      <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></li>
      <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../../../assets/images/soporte-equipos-computo.jpg" class="portada d-block w-100" alt="soporte">
        <div class="carousel-caption d-none d-md-block" style="background-color: white; color: black;">
          <h5>Asistencia Online</h5>
          <p>Atencion remota y presencial a equipos de computo</p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="../../../assets/images/desarrollo-software.jpg" class="portada d-block w-100" alt="remoto">
        <div class="carousel-caption d-none d-md-block" style="background-color: white; color: black;">
          <h5>Software</h5>
          <p>Desarrollo de paginas y aplicacines web</p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="../../../assets/images/cursos.jpg" class="portada d-block w-100" alt="cursos">
        <div class="carousel-caption d-none d-md-block" style="background-color: white; color: black;">
          <h5>Cursos y Capacitación</h5>
          <p>Capacitación en linea y presencial</p>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </a>
  </div>