<nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">MCTech</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav justify-content-center">
        <a class="nav-link active" aria-current="page" href="#about">Nosotros</a>
        <a class="nav-link" href="#servicios">Servicios</a>
        <!-- <a class="nav-link" href="#">Productos</a> -->
        <a class="nav-link" href="https://share.hsforms.com/1tcAMjqjGT02501EAXDu7Dw5ix60">Contactanos</a>
      </div>
    </div>
  </div>
</nav>