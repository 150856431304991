<div class="row featurette m-3" id="about">
  <div class="col-md-7">
    <h2 class="featurette-heading">Atención al cliente.   <span class="text-muted">Nuestra prioridad</span></h2>
    <p class="lead">Estamos comprometidos con nuestros clientes y es prioridad para nosotros proporcionarte una atención personalizada.</p>
  </div>
  <div class="col-md-5">
    <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"
      src="../../../assets/images/asistencia-tecnica.jpg" role="img" aria-label="Placeholder: 500x500"
      preserveAspectRatio="xMidYMid slice" focusable="false">
  </div>
</div>

<hr class="featurette-divider">

<div class="row featurette m-3">
  <div class="col-md-7 order-md-2">
    <h2 class="featurette-heading">En equipos de computo.   <span class="text-muted">Las mejores marcas</span></h2>
    <p class="lead">Venta y licenciamientos de equipos de computo desde un mouse hasta servidores de alta capacidad para que tu hogar o negocio este siempre a la vanguardia.</p>
  </div>
  <div class="col-md-5 order-md-1">
    <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"
      src="../../../assets/images/teclado.jpg" role="img" aria-label="Placeholder: 500x500"
      preserveAspectRatio="xMidYMid slice" focusable="false">
  </div>
</div>

<hr class="featurette-divider mb-3">