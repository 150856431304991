<div class="container marketing" id="servicios">

  <!-- Three columns of text below the carousel -->
  <div class="row">
    <div class="col-lg-4">
      <img class="bd-placeholder-img rounded-circle" 
           width="140" height="140" 
           src="../../../assets/images/logoMCTechservice.png"
           role="img" aria-label="Placeholder: 140x140" 
           preserveAspectRatio="xMidYMid slice" 
           focusable="false">

      <h2>Consultoría</h2>
      <p>Te asesoramos en tus proyectos desde el inicio hasta el fin, proporcionando una solución acorde a tus necesidades.</p>
      <a type="button" href="https://share.hsforms.com/1tcAMjqjGT02501EAXDu7Dw5ix60" class="btn btn-primary">
        Ver mas
      </a>
    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4">
      <img class="bd-placeholder-img rounded-circle" 
           width="140" height="140" 
           src="../../../assets/images/laptop.png"
           role="img" aria-label="Placeholder: 140x140" 
           preserveAspectRatio="xMidYMid slice" 
           focusable="false">

      <h2>Equpos de cómputo</h2>
      <p>Venta, configuración, reparación y mantenimiento presencial o remoto de equipos de cómputo.</p>
      <a type="button" href="https://share.hsforms.com/1tcAMjqjGT02501EAXDu7Dw5ix60" class="btn btn-primary">
        Ver mas
      </a>
    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4">
      <img class="bd-placeholder-img rounded-circle" 
           width="140" height="140" 
           src="../../../assets/images/cursos.png"
           role="img" aria-label="Placeholder: 140x140" 
           preserveAspectRatio="xMidYMid slice" 
           focusable="false">

      <h2>Cursos y capacitación</h2>
      <p>Preparamos a tu personal para que aprovechen todos los recursos computacionales que en tu empresa tienes.</p>
      <a type="button" href="https://share.hsforms.com/1tcAMjqjGT02501EAXDu7Dw5ix60" class="btn btn-primary">
        Ver mas
      </a>
    </div><!-- /.col-lg-4 -->

    <div class="row">
      <div class="col-lg-4">
        <img class="bd-placeholder-img rounded-circle" 
             width="140" height="140"
             src="../../../assets/images/software.png"
             role="img" aria-label="Placeholder: 140x140" 
             preserveAspectRatio="xMidYMid slice" 
             focusable="false">
    
        <h2>Software</h2>
        <p>Venta de licencias, instalación y configuración de software, construcción de paginas de internet, aplicaciones web y multiplataforma</p>
        <a type="button" href="https://share.hsforms.com/1tcAMjqjGT02501EAXDu7Dw5ix60" class="btn btn-primary">
          Ver mas
        </a>
      </div>
      <div class="col-lg-4">
        <img class="bd-placeholder-img rounded-circle" 
             width="140" height="140" 
             src="../../../assets/images/redes-informaticas.png"
             role="img" aria-label="Placeholder: 140x140" 
             preserveAspectRatio="xMidYMid slice" 
             focusable="false">
    
        <h2>Redes</h2>
        <p>Alambricas o inalambricas, conectamos tu negocio para una mejor administración de tus recursos internos o
          externos.
        </p>
        <a type="button" href="https://share.hsforms.com/1tcAMjqjGT02501EAXDu7Dw5ix60" class="btn btn-primary">
          Ver mas
        </a>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <img class="bd-placeholder-img rounded-circle" 
             width="140" height="140" 
             src="../../../assets/images/camara-seguridad.png"
             role="img" aria-label="Placeholder: 140x140" 
             preserveAspectRatio="xMidYMid slice" 
             focusable="false">
    
        <h2>CCTV</h2>
        <p>Venta, instalación y configuración de sistemas para monitoreo de casa o negocio para su visualización desde
          cualquier dispositivo.
        </p>
        <a type="button" href="https://share.hsforms.com/1tcAMjqjGT02501EAXDu7Dw5ix60" class="btn btn-primary">
          Ver mas
        </a>
      </div><!-- /.col-lg-4 -->
  </div>
</div>

<hr class="featurette-divider">